import React from 'react';
import donos from '../assets/donos.png';

function AboutUs() {
  return (
    <section className="">
      <div className='w-3/4 mx-auto py-16 text-primary text-2xl'>
        <h2 className="text-center text-primary font-bold text-3xl xl:text-4xl mb-16">SOBRE NÓS</h2>
        <div className="container mx-auto text-sm xl:text-xl flex flex-col-reverse lg:flex-row gap-8">
          <div className="lg:w-1/2 xl:w-8/12 2xl:w-8/12">
            <p className="mb-4 text-justify">
              Sabemos que muitos representantes se limitam a apenas tirar pedidos e ir embora. Nós
              queremos ir além da simples representação e nos tornar parceiros estratégicos. Com
              mais de 52 anos de experiência, entendemos que, quando o lojista prospera, todo o
              ecossistema do varejo evolui junto.
            </p>
            <p className="mb-4 text-justify">
              Por isso, hoje somos uma consultoria em moda, educação e tecnologia, oferecendo um
              showroom completo com grandes marcas. E além de adquirir sua próxima coleção, você
              encontra um ambiente com recursos valiosos para promover o crescimento sustentável
              do seu negócio, como:
            </p>
            <ul className="list-disc ml-6">
              <li className="mb-2 text-justify">Tendências de mercado.</li>
              <li className="mb-2 text-justify">Consultorias e imersões para discutir o futuro do seu negócio.</li>
              <li className="mb-2 text-justify">Treinamentos avançados para seus colaboradores.</li>
              <li className="mb-2 text-justify">Tecnologias de ponta para otimização de operações.</li>
              <li className="mb-2 text-justify">Uma rede de networking com diversos lojistas para potencializar sua experiência.</li>
            </ul>
            <p className="mt-4 text-justify">
              Queremos ser um verdadeiro aliado da evolução, capacitando seu negócio com
              inteligência comercial para fortalecer todo o ecossistema do varejo.
            </p>
          </div>
          <div className="lg:w-1/2 xl:w-4/12 2xl:w-4/12 flex items-center">
            <div className="w-full h-auto max-h-full flex justify-center xl:justify-start">
              <img src={donos} alt="Sobre nós" className="w-full h-auto object-contain rounded-3xl" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutUs;
