import React from 'react';
import grafismo from '../assets/grafismo1.png';

function Grafism() {
  return (
    <div className='w-screen h-screen fixed z--2'>
      <img className="w-1/4 h-auto bottom-0 right-0 fixed z--1" alt="Grafismo" src={grafismo} />
    </div> 
  );
}

export default Grafism;
