import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

const Whatsapp = () => {
  
  return (
    <>
      <div>
        <a
          href="https://api.whatsapp.com/send?phone=554899464593&text=Olá!%20Estou%20entrando%20em%20contato%20pelo%20site."
          target="_blank"
          rel="noopener noreferrer"
          title="Entrar em contato via WhatsApp"
          className={`fixed z-50 bg-green-500 hover:bg-green-600 bottom-6 right-6 w-16 h-16 lg:w-20 lg:h-20 rounded-full flex justify-center items-center cursor-pointer transition-opacity animate__animated animate__fadeInUp`}
        >
          <FontAwesomeIcon icon={faWhatsapp} alt="Ícone do WhatsApp" className="w-6 h-6 lg:w-10 lg:h-10 text-white" />
        </a>
      </div>
    </>
  );
};

export default Whatsapp;
