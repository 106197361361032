import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import Whatsapp from '../components/Whatsapp';
import Carousel from '../components/Carousel';
import AboutUs from '../components/AboutUsAura';
import Brands from '../components/Brands';
import Connection from '../components/Connection';
import AreaOfActivity from '../components/AreaOfActivity';
import PhotoCarousel from '../components/PhotoCarousel';
import Grafism from '../components/Grafism';
import Footer from '../components/Footer';

const Home = () => {
  return (
    <>
      <Header />
      <Whatsapp />
      <Carousel />
      <AboutUs />
      <Brands />
      <Connection />
      <AreaOfActivity />
      <PhotoCarousel />
      <Grafism />
      <Footer />
    </>
  );
};


export default Home;