import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function importAll(r) {
  return r.keys().map(r);
}

const images = importAll(require.context('../assets/events', false, /\.(png|jpe?g|svg)$/));

function PhotoCarousel() {

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    arrows: true,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <>
      <div className="container mx-auto text-sm text-center mb-16">
        <h2 className="text-primary font-bold text-3xl xl:text-4xl">EVENTOS</h2>
      </div>
      <div className="relative max-w-screen max-h-screen overflow-hidden">
        <Slider {...settings} className="w-full h-full">
          {images.map((image, index) => (
            <div key={index} className="flex justify-center items-center w-full h-full px-4">
              <img 
                src={image} 
                alt={`Slide ${index}`} 
                className="max-w-full max-h-[80vh] object-contain cursor-pointer" 
              />
            </div>
          ))}
        </Slider>
      </div>
    </>
  );
}

export default PhotoCarousel;
