import React from 'react';

function Connection() {
  return (
    <section className="z-10">
      <div className="w-3/4 mx-auto py-16 text-primary text-2xl">
        <h2 className="text-center text-primary font-bold text-3xl xl:text-4xl">CONEXÃO</h2>
        <div className="container mx-auto text-sm xl:text-xl flex flex-col lg:flex-row mt-16">
            <p className="mb-4 text-justify">
              Confira os melhores momentos do evento Conexão, promovido pelo Grupo Kyly e Aura Showroom!
              Foi uma jornada incrível de qualificação e networking para lojistas de Santa Catarina.
              Agradecemos a todos que participaram e contribuíram para o sucesso deste encontro.
              Juntos vamos evoluir todo o ecossistema do varejo!
            </p>
        </div>
        <div className="mt-8 relative w-full overflow-hidden" style={{ paddingTop: '56.25%' }}>
          <iframe 
            src="https://www.youtube.com/embed/6zv--W24mTE?si=9EvMSK9e6naQpKtC" 
            title="YouTube video player" 
            frameBorder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            referrerPolicy="strict-origin-when-cross-origin" 
            allowFullScreen 
            className="absolute top-0 left-0 w-full h-full"
          ></iframe>
        </div>
      </div>
    </section>
  );
}

export default Connection;
