import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import Whatsapp from '../components/Whatsapp';
import Carousel from '../components/Carousel';
import AboutUs from '../components/AboutUs';
import Grafism from '../components/Grafism';
import Footer from '../components/Footer';

const Coworking = () => {
  return (
    <>
      <Header />
      <Whatsapp />
      <Carousel />
      <AboutUs />
      <Grafism />
      <Footer />
    </>
  );
};


export default Coworking;