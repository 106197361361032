import React, { useEffect } from 'react';
import Header from "../components/Header";
import Whatsapp from '../components/Whatsapp';
import Carousel from '../components/Carousel';
import Footer from "../components/Footer";

const NotFound = () => {

  return (
      <>
        <div className="flex flex-col min-h-screen text-center">
        <Header />
        <Whatsapp />
        <Carousel />
          <div className="m-auto my-32 justify-center">
            <div className="animate__animated animate__fadeInDown animate__delay-1s">
              <h1 className="text-6xl font-bold text-primary mt-4">404</h1>
              <h1 className="text-4xl font-bold text-secondary">Página não encontrada</h1>
              <p className="text-secondary">Ops! A página que você procura não existe.</p>
            </div>
          </div>
        <Footer />
        </div>
      </>
    );
};

export default NotFound;
